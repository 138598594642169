.NewNote form textarea {
  height: 300px;
  font-size: 1.5rem;
}

.fileChoose {
  height: 100%;
}


.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  /* background-color: lightgray; */
  padding: 0rem;
  border: 1px solid #ccc;
}

.rdw-dropdown-selectedtext {
  color: #000;
  text-decoration: none;
}

.toolbar-class {
  border: 1px solid #ccc;
}