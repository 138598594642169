.App {
}
.color-nav {
  background-color: rgb(255, 255, 255);
}
.font-color {
  color: rgb(8, 85, 252);
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.navbar-brand > img {
  object-fit: cover;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(235, 195, 64);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
