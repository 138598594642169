.NFTListItem-img {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.NFTListItem-main-card {
  display: flex;
  border-radius: 20px;
  padding: 20 px;
  flex-direction: column;
  justify-content: center;
  min-height: 200 px;
  height: 400px;

  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 10px 10px 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.results {
  padding-top: 50 px;
  padding-top: 50 px;

  justify-content: center;
}

.textarea {
  height: 300px;
}

.imageHolder{
  min-height: 200px;      
  justify-content: center;
  
}

.history{
  justify-content: center;
}
